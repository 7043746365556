import React from 'react';
import { Button, Typography, Box, useTheme, Stack } from '@mui/material';
import { FiCheck, FiX, FiArrowRight } from 'react-icons/fi'; // Importing react-icons
import { Container, GridContainer, MarkDown } from 'components';
import AskIndmenButton from './ask-indemn-button';
import { FiMessageCircle } from 'react-icons/fi';

export interface PriceTier {
  title: string;
  price?: string;
  description: string;
  inclusions: string[];
  exclusions: string[];
  ctaLabel: string;
  signUpUrl?: string;
  billingInterval: string;
  createdAt: string;
  ctaType: 'talk' | 'signup';
  ctaUtterance: string;
  currency: string;
  isEnterprise: boolean;
  is_recommended: boolean;
}

const PricingTier = ({ ...data }: PriceTier): JSX.Element => {
  const { typography, palette } = useTheme();
  const {
    title,
    price,
    inclusions,
    exclusions,
    is_recommended,
    ctaUtterance,
    ctaType,
    ctaLabel,
    description,
    signUpUrl,
  } = data;

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'max-content max-content 1fr',
        position: 'relative',
        padding: '20px 16px',
        borderRadius: '12px',
        background: is_recommended ? '#172554' : palette.primary[50],
        border: `1px solid ${
          is_recommended ? palette.primary[500] : palette.primary[300]
        }`,
        gap: { xs: 2.5 },
        height: 'fit-content',
      }}
    >
      <Box
        display="flex"
        gap={1.5}
        flexDirection="column"
        alignItems={{ xs: 'flex-start', md: 'flex-start' }}
        pt={2}
      >
        <MarkDown
          color={is_recommended ? 'primary.50' : 'primary.800'}
          sx={{
            ...typography.base,
            ...typography.semibold,
            whiteSpace: 'pre-line',
          }}
          text={title}
        />
        <MarkDown
          color={is_recommended ? 'primary.50' : 'primary.800'}
          sx={{
            ...typography.sm,
            ...typography.regular,
            whiteSpace: 'pre-line',
          }}
          text={description}
        />
        {price ? (
          <Box
            display="flex"
            gap={1}
            flexDirection="column"
            alignItems={{ xs: 'flex-start' }}
            paddingTop={'12px'}
            paddingBottom={'12px'}
          >
            <Typography
              color={is_recommended ? 'white' : 'monochrome.gray.800'}
              sx={{ ...typography['3xl'], ...typography.bold }}
            >
              ${price}
            </Typography>
            <Typography
              color={
                is_recommended ? 'monochrome.gray.100' : 'monochrome.gray.500'
              }
              sx={{ ...typography.xs, ...typography.regular }}
            >
              per month
            </Typography>
          </Box>
        ) : (
          <Box height="auto" />
        )}
      </Box>

      {ctaType === 'signup' && (
        <Button
          variant="contained"
          endIcon={<FiArrowRight size={20} />}
          size="medium"
          color={'secondary'}
          sx={{ width: { xs: 'fit-content', md: 1 } }}
          href={signUpUrl}
          target="_blank"
        >
          {ctaLabel}
        </Button>
      )}

      {ctaType === 'talk' && (
        <AskIndmenButton
          variant="outlined"
          endIcon={<FiMessageCircle size={12} />}
          size="small"
          message={ctaUtterance}
          sx={{
            mb: 1,
          }}
        >
          {ctaLabel}
        </AskIndmenButton>
      )}

      <Box display="flex" flexDirection="column" gap={2} mt={2}>
        {inclusions.map((txt) => (
          <Box
            display="grid"
            gridTemplateColumns="16px 1fr"
            alignItems="flex-start"
            gap={1}
            key={txt}
          >
            <FiCheck size={16} color={palette.tertiary[500]} />
            <Typography
              color={
                is_recommended ? 'monochrome.gray.50' : 'monochrome.gray.700'
              }
              sx={{ ...typography.xs, ...typography.medium }}
            >
              {txt}
            </Typography>
          </Box>
        ))}

        {exclusions.map((txt) => (
          <Box
            display="grid"
            gridTemplateColumns="16px 1fr"
            alignItems="flex-start"
            gap={1}
            key={txt}
          >
            <FiX size={16} color={palette.monochrome.gray[500]} />{' '}
            {/* Replacing FeatherIcon with FiX */}
            <Typography
              color={
                is_recommended ? 'monochrome.gray.50' : 'monochrome.gray.700'
              }
              sx={{ ...typography.xs, ...typography.regular }}
            >
              {txt}
            </Typography>
          </Box>
        ))}
      </Box>
      {is_recommended && (
        <Box
          position="absolute"
          top={-4}
          right={{ xs: 'unset', md: -4 }}
          left={{ xs: -4, md: 'unset' }}
          bgcolor={palette.tertiary[200]}
        >
          <Typography
            color="tertiary.900"
            sx={{ ...typography.sm, ...typography.medium }}
            padding="4px 8px"
          >
            RECOMMENDED
          </Typography>
        </Box>
      )}
    </Box>
  );
};

interface TPricingContent {
  cta_label: string;
  cta_type: string;
  cta_utterance: string;
  page_description: string;
  page_title: string;
}

const PricingContent = (props: TPricingContent): JSX.Element => {
  const { typography } = useTheme();
  const { cta_label, cta_utterance, page_description, page_title } = props;
  return (
    <Stack gap={3}>
      <MarkDown
        sx={{
          ...typography['2xl'],
          ...typography.bold,
          whiteSpace: 'pre-line',
        }}
        text={page_title}
      />
      <MarkDown
        color="primary.900"
        sx={{
          ...typography.base,
          ...typography.regular,
          whiteSpace: 'pre-line',
        }}
        text={page_description}
      />
      <AskIndmenButton
        variant="outlined"
        endIcon={<FiMessageCircle size={12} />}
        size="small"
        message={cta_utterance}
        sx={{
          width: 'fit-content',
        }}
      >
        {cta_label}
      </AskIndmenButton>
    </Stack>
  );
};

interface TPricing extends TPricingContent {
  prices: PriceTier[];
}

const Pricing = ({ prices, ...pageConent }: TPricing): JSX.Element => {
  const pricingCount = prices.length; // Count the number of pricing tiers

  return (
    <GridContainer>
      <Container>
        <Box
          sx={{
            display: {
              xs: 'flex',
              md: 'grid',
            },
            gridTemplateColumns: {
              xs: 'unset',
              md: pricingCount === 1 ? '1fr 1fr' : 'repeat(8, 1fr)',
              xl:
                pricingCount < 4 ? `1fr ${pricingCount}fr` : 'repeat(12, 1fr)',
            },
            flexDirection: {
              xs: 'column',
              md: 'unset',
            },
            gap: 3,
          }}
        >
          <Box
            sx={{
              gridColumn: {
                md: pricingCount === 1 ? undefined : 'span 4',
                xl: pricingCount < 4 ? '1 / 2' : 'span 6',
              },
            }}
          >
            <PricingContent {...pageConent} />
          </Box>

          <Box
            sx={{
              width: {
                xs: 1,
                md: pricingCount < 4 ? 1 : 6 / 8,
                xl: 1,
              },
              justifySelf: 'center',
              display: 'grid',
              gridTemplateColumns: {
                md:
                  pricingCount < 4 ? `repeat(${pricingCount}, 1fr)` : '1fr 1fr',
                xl: `repeat(${pricingCount}, 1fr)`,
              },
              gridColumn: {
                md: pricingCount === 1 ? undefined : 'span 8',
                xl: pricingCount < 4 ? '2 / -1' : 'span 12',
              },
              gap: 3,
              mt: {
                md: pricingCount === 1 ? 0 : 5,
                xl: pricingCount < 4 ? 0 : 5,
              },
            }}
          >
            {prices.map((price) => (
              <PricingTier {...price} key={price.title} />
            ))}
          </Box>
        </Box>
      </Container>
    </GridContainer>
  );
};

export default Pricing;
