// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    overflow-x: hidden !important;
}

html {
    scroll-behavior: smooth;
}

#products-carousel>div>div {
    overflow: visible;
    margin-left: 25%;
    /* remove scroll on carousel */
    touch-action: none;
}

.react-tel-input::placeholder {
    color: #6b7280;
}

@media only screen and (max-width: 1280px) and (min-width: 768px) {
    #products-carousel>div>div {
        margin-left: 12.5%;
    }
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,8BAA8B;IAC9B,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ","sourcesContent":["body {\n    overflow-x: hidden !important;\n}\n\nhtml {\n    scroll-behavior: smooth;\n}\n\n#products-carousel>div>div {\n    overflow: visible;\n    margin-left: 25%;\n    /* remove scroll on carousel */\n    touch-action: none;\n}\n\n.react-tel-input::placeholder {\n    color: #6b7280;\n}\n\n@media only screen and (max-width: 1280px) and (min-width: 768px) {\n    #products-carousel>div>div {\n        margin-left: 12.5%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
