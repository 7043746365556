import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Main from 'layouts/main';
import * as constants from 'utils/constants';
import { generateMetaTags } from '../pageHelmet';
import { BotHostContainer, Faq, Pricing } from 'views/components';
import ChatLoader from 'context/chatLoader';
import { ChatbotStoreProvider } from 'context/chatbot-state';
import { Loading } from 'components';
import NotFound from 'views/not-found';
import { useParams } from 'react-router-dom';

const Price = (): JSX.Element => {
  const { slug } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    const fetchPricing = async () => {
      setLoading(true);
      try {
        const res = await (
          await fetch(
            `${process.env.REACT_APP_OPS_API_HOST}/marketing/price/pages/${slug || 'main'}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            },
          )
        ).json();

        setData(res.page);
      } catch (err) {
        console.log(err);
      }

      setLoading(false);
    };

    fetchPricing();
  }, [slug]);

  if (loading) return <Loading loading={loading} />;

  if (!data || data.inactive) return <NotFound />;

  return (
    <ChatbotStoreProvider>
      <Main>
        {generateMetaTags(
          constants.PAGE_CONTENT.PRICING.TITLE,
          constants.PAGE_CONTENT.PRICING.DESCRIPTION,
          constants.PAGE_CONTENT.PRICING.OG_IMAGE,
          window.location.href,
          constants.PAGE_CONTENT.PRICING.KEYWORDS,
        )}
        <Box pt={20}>
          <Pricing
            prices={data.show_tiers}
            {...data}
          />
        </Box>
        <Faq faqs={data.faqs.map(question => ({ question, faq_intent: question }))} />
        <BotHostContainer width={{ xs: 1, xl: 12 / 12 }} />
        <ChatLoader />
      </Main>
    </ChatbotStoreProvider>
  );
};

export default Price;
