import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { OutlinedInput, Stack, Typography, useTheme } from '@mui/material';
import { Container, GridContainer, MarkDown, Image } from 'components';
import { FiPhone } from 'react-icons/fi'; // Importing the phone icon from react-icons
import AskIndmenButton from './ask-indemn-button';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { isValidNumber } from 'libphonenumber-js';
import * as constants from 'utils/constants';

const SectionVoice = (): JSX.Element => {
  const { typography, palette } = useTheme();

  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [countryCode, setCountryCode] = useState('');
  const [isPhoneValid, setIsPhoneValid] = useState(false);

  // Fetch the user's country code based on IP address
  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        setCountryCode(response.data.country_code.toLowerCase());
      } catch (error) {
        console.error('Error fetching country code:', error);
      }
    };

    fetchCountryCode();
  }, []);

  // Updates the form's state on input change
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handles phone number change and validates based on country code
  const handlePhoneChange = (value, country) => {
    setFormData({ ...formData, phoneNumber: value });
    const isValid = isValidNumber(value, country?.countryCode.toUpperCase());
    setIsPhoneValid(isValid);
  };

  // Submit handler to send the API request
  const handleSubmit = async () => {
    if (!isPhoneValid) {
      return;
    }

    setIsSubmitting(true);

    try {
      await axios.post(
        `${process.env.REACT_APP_SOCKET_MIDDLEWARE_URL}/request-demo`,
        {
          phoneNumber: formData.phoneNumber,
          name: formData.name,
        },
      );
    } catch (error) {
      console.error(error);
    }

    setIsSubmitting(false);
  };

  return (
    <GridContainer>
      <Container
        display="flex"
        flexDirection="column"
        alignItems="left"
        gap={4}
        width={{ xs: 1, md: 7 / 8, xl: 7 / 12 }}
      >
        <Image
          src={constants.CLOUDINARY_IMAGES.PARTNER_US_HERO_IMAGE}
          alt="section-voice"
          sx={{
            position: 'absolute',
            width: {
              xs: '48vh',
              sm: '54vh',
              md: '60vh',
              lg: '66vh',
              xl: '72vh',
            },
            height: {
              xs: '48vh',
              sm: '54vh',
              md: '60vh',
              lg: '66vh',
              xl: '72vh',
            },
            right: 0,
            top: { xs: '-6vh', md: '-14vh', xl: '-9vh' },
          }}
          imageProps={{
            style: {
              objectFit: 'cover',
              mixBlendMode: 'darken',
              opacity: 0.3,
            },
          }}
        />
        <MarkDown
          sx={{
            ...typography['2xl'],
            ...typography.bold,
            whiteSpace: 'pre-line',
            textAlign: 'left',
            zIndex: 2,
          }}
          text="Powering telephony of the future"
        />
        <MarkDown
          sx={{
            ...typography.lg,
            ...typography.regular,
            textAlign: 'left',
            zIndex: 2,
          }}
          text="Harness the power of AI-driven conversations and witness up to __10X more online purchase behavior.__ The paradigm shift isn’t coming - it’s already here."
        />
        <Stack gap={0.5} width={3 / 4}>
          <Typography
            sx={{ ...typography.sm, ...typography.medium }}
            color="monochrome.gray.500"
          >
            Full name
          </Typography>
          <OutlinedInput
            id="name" // Added unique id
            name="name"
            placeholder="Ada Lovelace"
            autoComplete="name"
            value={formData.name}
            onChange={handleChange}
          />
        </Stack>
        <Stack gap={0.5} width={3 / 4}>
          <Typography
            sx={{ ...typography.sm, ...typography.medium }}
            color="monochrome.gray.500"
          >
            Phone number
          </Typography>
          <PhoneInput
            inputProps={{
              id: 'phoneNumber',
            }}
            country={countryCode}
            containerStyle={{
              borderRadius: 6,
              border: 'none',
              outline: 'none',
            }}
            inputStyle={{
              width: '100%',
              height: 44,
              background: 'white',
              color: palette.monochrome.gray[800],
              border: `1px solid ${palette.monochrome.gray[200]}`,
            }}
            buttonStyle={{
              borderRadius: '6px 0 0 6px',
              background: 'white',
              border: `1px solid ${palette.monochrome.gray[200]}`,
            }}
            value={formData.phoneNumber}
            onChange={handlePhoneChange}
          />
        </Stack>
        <AskIndmenButton
          variant="outlined"
          endIcon={<FiPhone />}
          color="inherit"
          size="medium"
          sx={{ width: 'fit-content' }}
          onClick={handleSubmit}
          disabled={isSubmitting || !isPhoneValid}
        >
          {isSubmitting ? 'Calling...' : 'Get a call'}
        </AskIndmenButton>
      </Container>
    </GridContainer>
  );
};

export default SectionVoice;
